<template>
    <metadata-list name="Trader" url="/api/traders" link="trader" />
</template>

<script>
import MetadataList from '@/components/MetadataList.vue';

export default {
    name: 'TraderList',

    components: {
        MetadataList
    }
};
</script>
